document.addEventListener("DOMContentLoaded", function () {
  window.addEventListener("keydown", function (e) {
    if (e.key === "d") {
      const betaDownloadLinks = document.querySelectorAll(
        ".beta-download-link"
      );
      betaDownloadLinks.forEach((betaDownloadLink) => {
        betaDownloadLink.classList.toggle("is-hidden");
      });
    }
  });
});
